.landing-image{
    background-image: url('./images/stanford_scene.jpg') !important;
    background-size: cover !important;
    height: 100vh;
}
.landing-image .card{
    top:30%;
    width: 400px;
}

.stanford-bar{
    height: 30px !important;
    background-color:#8c1515 !important;
    box-shadow:inset 0 -4px 8px -2px rgba(0, 0, 0, 0.2) !important; /* Horizontal offset , vertical offset, blur, internal spacing */
    min-height:30px;
    border: none !important;
    color: white !important;
}
/*  */
/* html, body, #root{
    height: fit-content;
    min-height: 100vh;
} */