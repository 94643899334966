.rdt_TableBody {
    overflow: hidden;
}

table {
    border-collapse: collapse;
}

table td {
    border: 1px solid #333;
    padding:5px;
}