.edit_project_dropdown .text{
    font-weight: bold;
}

.edit_project_dropdown {
    border-bottom: 1px solid red;
}

/* edit_project_dropdown_mobile {
    float: right;
    background-color: #db2828 !important;
    color:white !important;
    width: 10px;
    margin: 0px !important;
} */
/* .edit_project_dropdown:hover {
    color:black;
} */
.detail_label {
    margin-bottom: 5px !important;
}

.detail_label_warning {
    margin-bottom:5px;
    color:red !important;
}

.detail_container {
    margin-top: 5px !important;
}

.closeButton {
    color: red !important;
    background: none !important;
    /* box-shadow: none !important; */
    /* -webkit-box-shadow: none !important; */
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0px !important;
    border: none !important;
    
}
.selectMe {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .5) !important;
    z-index: 1;
}

.disabledContainer {
    height: 100vh;
    width: 100vw;
    top: 0px;
    position: absolute;
    z-index: 5;
    display: flex;
    justify-content: center;

}
/* .closeButton button:hover{
    box-shadow: none !important;
    border: none !important;
    background-color: black !important;
    background:black !important;
    color: black !important;
    border-bottom-color: aqua !important;
} */