
#brandbar{
    margin-bottom: 10px;
    min-height: 40px !important;
}

.notification-entry .label{
    display: inline-block;
}

.notification-entry .header{
    display: inline-block !important;
    margin-left: 5px !important;
}

.notification-entry .icon{
    float: right !important;
    margin: 0px !important;
}

.notification-mobile {
    max-width: 320px;
    overflow: scroll !important;
}