

.irbInput button {
    background: transparent !important;
    border-width: 1px 1px 1px 0px !important;
    border-style: solid !important;
    border-color: lightgrey !important;
}

.hideSearch .search {
    visibility: hidden;
}